<template>
  <div class="fixed inset-0 transition-opacity">
    <div @click="$emit('close')"
    class="absolute inset-0 bg-black opacity-50">
        
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>