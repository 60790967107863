<template>
    <section class="w-screen h-screen flex flex-col justify-center items-center antialiased p-10">
      
      <h1 class="text-5xl mt-10" >404 </h1>
      <h2 class="text-slate-400">Page Not Found</h2>
      <p class="text-center">The Page you are looking for doesn't exist or an other error occurred.
  
      </p>
    </section>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>